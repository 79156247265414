import React, { SVGProps } from 'react';

export const Plus = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        d="M7.99998 1.00233C8.49703 1.00233 8.89998 1.40528 8.89998 1.90233L8.89998 7.1L14.0976 7.1C14.5947 7.1 14.9976 7.50295 14.9976 8C14.9976 8.49706 14.5947 8.9 14.0976 8.9L8.89998 8.9L8.89998 14.0976C8.89998 14.5947 8.49703 14.9976 7.99998 14.9976C7.50292 14.9976 7.09998 14.5947 7.09998 14.0976L7.09998 8.9L1.90232 8.9C1.40526 8.9 1.00232 8.49706 1.00232 8C1.00232 7.50295 1.40526 7.1 1.90232 7.1L7.09998 7.1L7.09998 1.90233C7.09998 1.40528 7.50292 1.00233 7.99998 1.00233Z"
        fill="currentcolor"
      />
    </svg>
  );
};
