import React, { SVGProps } from 'react';

export const MarkCheck = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0ZM11.8864 6.38637C12.2379 6.0349 12.2379 5.46505 11.8864 5.11358C11.5349 4.76211 10.9651 4.76211 10.6136 5.11358L6.75001 8.97718L5.3864 7.61358C5.03493 7.26211 4.46508 7.26211 4.11361 7.61358C3.76214 7.96505 3.76214 8.5349 4.11361 8.88637L6.11361 10.8864C6.28239 11.0552 6.51131 11.15 6.75001 11.15C6.9887 11.15 7.21762 11.0552 7.3864 10.8864L11.8864 6.38637Z"
        fill="currentcolor"
      />
    </svg>
  );
};
