import React, { SVGProps } from 'react';

export const MarkInfo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0ZM7.995 3.60001C7.49794 3.60001 7.095 4.00295 7.095 4.50001C7.095 4.99706 7.49794 5.40001 7.995 5.40001H8.005C8.50206 5.40001 8.905 4.99706 8.905 4.50001C8.905 4.00295 8.50206 3.60001 8.005 3.60001H7.995ZM8.895 8.00001C8.895 7.50295 8.49206 7.10001 7.995 7.10001C7.49794 7.10001 7.095 7.50295 7.095 8.00001V11.5C7.095 11.9971 7.49794 12.4 7.995 12.4C8.49206 12.4 8.895 11.9971 8.895 11.5V8.00001Z"
        fill="currentcolor"
      />
    </svg>
  );
};
