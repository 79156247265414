import React, { SVGProps, useId } from 'react';

export const MarkCheckDotted = (props: SVGProps<SVGSVGElement>) => {
  const gradientId = useId();

  return (
    <svg viewBox="0 0 16 16" {...props}>
      <g clipPath={`url(#${gradientId})`}>
        <path
          d="M7.15817 1.85656C7.43304 1.8193 7.71402 1.8 8 1.8C8.28598 1.8 8.56696 1.8193 8.84183 1.85656C9.33438 1.92333 9.7878 1.57817 9.85457 1.08562C9.92134 0.593067 9.57618 0.139647 9.08362 0.0728767C8.72884 0.0247825 8.36708 0 8 0C7.63292 0 7.27116 0.0247825 6.91638 0.0728765C6.42383 0.139647 6.07866 0.593066 6.14543 1.08562C6.2122 1.57817 6.66562 1.92333 7.15817 1.85656Z"
          fill="currentColor"
        />
        <path
          d="M4.25085 3.06151C4.64649 2.76062 4.7233 2.19598 4.42241 1.80034C4.12152 1.4047 3.55688 1.32789 3.16124 1.62877C2.58378 2.06794 2.06794 2.58378 1.62878 3.16123C1.32789 3.55687 1.4047 4.12152 1.80034 4.42241C2.19598 4.7233 2.76063 4.64649 3.06151 4.25085C3.40231 3.80273 3.80273 3.40231 4.25085 3.06151Z"
          fill="currentColor"
        />
        <path
          d="M12.8388 1.62878C12.4431 1.32789 11.8785 1.4047 11.5776 1.80034C11.2767 2.19598 11.3535 2.76063 11.7491 3.06151C12.1973 3.40231 12.5977 3.80273 12.9385 4.25085C13.2394 4.64649 13.804 4.7233 14.1997 4.42241C14.5953 4.12152 14.6721 3.55688 14.3712 3.16124C13.9321 2.58378 13.4162 2.06794 12.8388 1.62878Z"
          fill="currentColor"
        />
        <path
          d="M1.85656 7.15817C1.92333 6.66562 1.57817 6.2122 1.08562 6.14543C0.593067 6.07866 0.139647 6.42382 0.0728767 6.91637C0.0247825 7.27116 0 7.63292 0 8C0 8.36707 0.0247825 8.72884 0.0728765 9.08362C0.139647 9.57617 0.593066 9.92134 1.08562 9.85457C1.57817 9.7878 1.92333 9.33438 1.85656 8.84183C1.8193 8.56696 1.8 8.28598 1.8 8C1.8 7.71402 1.8193 7.43304 1.85656 7.15817Z"
          fill="currentColor"
        />
        <path
          d="M15.9271 6.91638C15.8604 6.42383 15.4069 6.07866 14.9144 6.14543C14.4218 6.2122 14.0767 6.66562 14.1434 7.15817C14.1807 7.43304 14.2 7.71402 14.2 8C14.2 8.28598 14.1807 8.56696 14.1434 8.84183C14.0767 9.33438 14.4218 9.7878 14.9144 9.85457C15.4069 9.92134 15.8604 9.57618 15.9271 9.08362C15.9752 8.72884 16 8.36708 16 8C16 7.63292 15.9752 7.27116 15.9271 6.91638Z"
          fill="currentColor"
        />
        <path
          d="M3.06151 11.7491C2.76062 11.3535 2.19598 11.2767 1.80034 11.5776C1.4047 11.8785 1.32789 12.4431 1.62877 12.8388C2.06794 13.4162 2.58378 13.9321 3.16123 14.3712C3.55687 14.6721 4.12152 14.5953 4.42241 14.1997C4.7233 13.804 4.64649 13.2394 4.25085 12.9385C3.80273 12.5977 3.40231 12.1973 3.06151 11.7491Z"
          fill="currentColor"
        />
        <path
          d="M14.3712 12.8388C14.6721 12.4431 14.5953 11.8785 14.1997 11.5776C13.804 11.2767 13.2394 11.3535 12.9385 11.7491C12.5977 12.1973 12.1973 12.5977 11.7491 12.9385C11.3535 13.2394 11.2767 13.804 11.5776 14.1997C11.8785 14.5953 12.4431 14.6721 12.8388 14.3712C13.4162 13.9321 13.9321 13.4162 14.3712 12.8388Z"
          fill="currentColor"
        />
        <path
          d="M7.15817 14.1434C6.66562 14.0767 6.2122 14.4218 6.14543 14.9144C6.07866 15.4069 6.42382 15.8604 6.91637 15.9271C7.27116 15.9752 7.63292 16 8 16C8.36707 16 8.72884 15.9752 9.08362 15.9271C9.57617 15.8604 9.92134 15.4069 9.85457 14.9144C9.7878 14.4218 9.33438 14.0767 8.84183 14.1434C8.56696 14.1807 8.28598 14.2 8 14.2C7.71402 14.2 7.43304 14.1807 7.15817 14.1434Z"
          fill="currentColor"
        />
        <path
          d="M11.8864 6.38637C12.2379 6.0349 12.2379 5.46505 11.8864 5.11358C11.5349 4.76211 10.9651 4.76211 10.6136 5.11358L6.75001 8.97718L5.3864 7.61358C5.03493 7.26211 4.46508 7.26211 4.11361 7.61358C3.76214 7.96505 3.76214 8.5349 4.11361 8.88637L6.11361 10.8864C6.28239 11.0552 6.51131 11.15 6.75001 11.15C6.9887 11.15 7.21762 11.0552 7.3864 10.8864L11.8864 6.38637Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={gradientId}>
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
