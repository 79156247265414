import React, { SVGProps } from 'react';

export const Reload = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        d="M11.8574 2.1587C12.355 2.48731 12.8055 2.87607 13.2 3.31386L13.2 2.06016C13.2 1.5631 13.603 1.16016 14.1 1.16016C14.5971 1.16016 15 1.5631 15 2.06016L15 5.5605C15 6.11278 14.5523 6.56049 14 6.56051L10.5016 6.56056C10.0045 6.56057 9.60158 6.15763 9.60157 5.66057C9.60157 5.16352 10.0045 4.76057 10.5016 4.76056L12.0674 4.76053C11.7291 4.33569 11.3242 3.96388 10.8654 3.6609C9.95077 3.05693 8.86871 2.75741 7.77372 2.80511C6.67874 2.85281 5.62682 3.24528 4.7682 3.92649C3.90958 4.60769 3.28816 5.54279 2.9927 6.59824L2.98809 6.59695C2.86531 6.94792 2.53123 7.19966 2.13835 7.19966C1.64129 7.19966 1.23835 6.79672 1.23835 6.29966C1.23835 6.18255 1.26072 6.07066 1.30142 5.96804C1.71439 4.60657 2.53292 3.40197 3.64934 2.51623C4.80522 1.59919 6.22131 1.07084 7.69538 1.00663C9.16945 0.942425 10.6261 1.34564 11.8574 2.1587Z"
        fill="currentcolor"
      />
      <path
        d="M1.00006 9.99968C1.00006 9.4474 1.44776 8.99969 2.00004 8.99968L5.4985 8.99962C5.99555 8.99962 6.3985 9.40256 6.39851 9.89961C6.39851 10.3967 5.99557 10.7996 5.49852 10.7996L3.61822 10.7997C4.00819 11.41 4.52361 11.9356 5.13465 12.3391C6.04925 12.9431 7.13131 13.2426 8.22629 13.1949C9.32128 13.1472 10.3732 12.7547 11.2318 12.0735C12.0904 11.3923 12.7119 10.4572 13.0073 9.40177L13.0119 9.40306C13.1347 9.05208 13.4688 8.80034 13.8617 8.80034C14.3587 8.80034 14.7617 9.20329 14.7617 9.70035C14.7617 9.81746 14.7393 9.92935 14.6986 10.032C14.2856 11.3934 13.4671 12.598 12.3507 13.4838C11.1948 14.4008 9.77871 14.9292 8.30464 14.9934C6.83056 15.0576 5.3739 14.6544 4.14266 13.8413C3.64506 13.5127 3.19457 13.124 2.80007 12.6862L2.80007 13.5C2.80007 13.9971 2.39713 14.4 1.90007 14.4C1.40302 14.4 1.00007 13.9971 1.00007 13.5L1.00006 9.99968Z"
        fill="currentcolor"
      />
    </svg>
  );
};
