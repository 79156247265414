import { SVGProps, useId } from 'react';

export const Privado = (props: SVGProps<SVGSVGElement>) => {
  const gradientId = useId();

  return (
    <svg viewBox="0 0 72 16" fill="none" {...props}>
      <g clipPath={`url(#${gradientId})`}>
        <path
          d="M0.968262 3.24166V12.8458C0.968262 14.5822 2.37588 15.9898 4.11227 15.9898H13.7164C15.4528 15.9898 16.8604 14.5822 16.8604 12.8458V3.24166C16.8604 1.50527 15.4528 0.0976562 13.7164 0.0976562H4.11227C2.37588 0.0976562 0.968262 1.50527 0.968262 3.24166Z"
          fill="#99FE5B"
        />
        <path
          d="M9.39355 13.0983C10.7234 13.0983 11.9987 12.5701 12.939 11.6298C13.8793 10.6895 14.4076 9.41411 14.4076 8.08432C14.4076 6.75452 13.8793 5.47921 12.939 4.53887C11.9987 3.59857 10.7234 3.07031 9.39355 3.07031V13.0983Z"
          fill="#131313"
        />
        <path d="M7.17877 7.01367H5.19141V13.0974H7.17877V7.01367Z" fill="#131313" />
        <path
          d="M6.175 5.66544C6.89164 5.66544 7.47259 5.08451 7.47259 4.36788C7.47259 3.65124 6.89164 3.07031 6.175 3.07031C5.45837 3.07031 4.87744 3.65124 4.87744 4.36788C4.87744 5.08451 5.45837 5.66544 6.175 5.66544Z"
          fill="#131313"
        />
        <path
          d="M64.8218 3.73242H67.5271C68.3468 3.73242 69.0847 3.89637 69.7405 4.22429C70.4043 4.55222 70.9291 5.03998 71.3144 5.68758C71.7081 6.33523 71.9048 7.11812 71.9048 8.03627C71.9048 8.95445 71.7081 9.73733 71.3144 10.3849C70.9291 11.0326 70.4043 11.5203 69.7405 11.8483C69.0847 12.1762 68.3468 12.3401 67.5271 12.3401H64.8218V3.73242ZM67.4288 11.0736C67.9781 11.0736 68.478 10.9711 68.9289 10.7662C69.3799 10.553 69.7405 10.221 70.011 9.77011C70.2897 9.31103 70.429 8.73309 70.429 8.03627C70.429 7.33947 70.2897 6.7615 70.011 6.30245C69.7405 5.84336 69.3799 5.51134 68.9289 5.30641C68.478 5.09326 67.9781 4.98667 67.4288 4.98667H66.2357V11.0736H67.4288Z"
          fill="#131313"
        />
        <path
          d="M62.3217 6.14374H63.6621V12.3413H62.3217V6.14374ZM62.9857 5.11081C62.7399 5.11081 62.5347 5.02884 62.3708 4.86486C62.2069 4.69271 62.125 4.48778 62.125 4.25003C62.125 4.0041 62.2069 3.79915 62.3708 3.6352C62.5347 3.46304 62.7399 3.37695 62.9857 3.37695C63.2398 3.37695 63.4489 3.46304 63.6128 3.6352C63.7849 3.79915 63.8712 4.0041 63.8712 4.25003C63.8712 4.48778 63.7849 4.69271 63.6128 4.86486C63.4489 5.02884 63.2398 5.11081 62.9857 5.11081Z"
          fill="#131313"
        />
        <path
          d="M60.3485 12.4383C60.0946 12.4383 59.8855 12.3564 59.7215 12.1924C59.5575 12.0285 59.4756 11.8235 59.4756 11.5776C59.4756 11.3316 59.5575 11.1267 59.7215 10.9627C59.8855 10.7988 60.0946 10.7168 60.3485 10.7168C60.6027 10.7168 60.8118 10.7988 60.9757 10.9627C61.1396 11.1267 61.2216 11.3316 61.2216 11.5776C61.2216 11.8235 61.1396 12.0285 60.9757 12.1924C60.8118 12.3564 60.6027 12.4383 60.3485 12.4383Z"
          fill="#131313"
        />
        <path
          d="M55.9036 12.4392C55.2887 12.4392 54.7395 12.304 54.2558 12.0334C53.772 11.7547 53.3908 11.3735 53.1123 10.8898C52.8418 10.4062 52.7065 9.85692 52.7065 9.24206C52.7065 8.62723 52.8418 8.07798 53.1123 7.5943C53.3908 7.11065 53.772 6.73354 54.2558 6.46301C54.7395 6.18427 55.2887 6.04492 55.9036 6.04492C56.5185 6.04492 57.0677 6.18427 57.5514 6.46301C58.0351 6.73354 58.4122 7.11065 58.6827 7.5943C58.9614 8.07798 59.1008 8.62723 59.1008 9.24206C59.1008 9.85692 58.9614 10.4062 58.6827 10.8898C58.4122 11.3735 58.0351 11.7547 57.5514 12.0334C57.0677 12.304 56.5185 12.4392 55.9036 12.4392ZM54.0591 9.24206C54.0591 9.84053 54.2271 10.3283 54.5631 10.7054C54.9075 11.0825 55.3544 11.271 55.9036 11.271C56.4528 11.271 56.8956 11.0825 57.2316 10.7054C57.5759 10.3283 57.748 9.84053 57.748 9.24206C57.748 8.64362 57.5759 8.15586 57.2316 7.77876C56.8956 7.40165 56.4528 7.21313 55.9036 7.21313C55.3544 7.21313 54.9075 7.40165 54.5631 7.77876C54.2271 8.15586 54.0591 8.64362 54.0591 9.24206Z"
          fill="#131313"
        />
        <path
          d="M48.7406 12.4392C48.1257 12.4392 47.5927 12.304 47.1417 12.0334C46.6991 11.7629 46.3592 11.3858 46.1212 10.9022C45.8916 10.4185 45.7769 9.86509 45.7769 9.24208C45.7769 8.61904 45.8916 8.06569 46.1212 7.58202C46.3592 7.09836 46.6991 6.72124 47.1417 6.45073C47.5847 6.1802 48.1091 6.04494 48.716 6.04494C49.1093 6.04494 49.4742 6.12691 49.8103 6.29084C50.1547 6.44662 50.4211 6.65566 50.6096 6.91798V3.63477H51.9499V12.3408H50.6096V11.5539C50.4619 11.8162 50.2161 12.0293 49.8717 12.1933C49.5276 12.3572 49.1504 12.4392 48.7406 12.4392ZM47.1173 9.24208C47.1173 9.8487 47.2814 10.3406 47.6092 10.7177C47.937 11.0866 48.3716 11.271 48.9127 11.271C49.4537 11.271 49.8881 11.0866 50.2161 10.7177C50.5439 10.3406 50.7078 9.8487 50.7078 9.24208C50.7078 8.62725 50.5439 8.13536 50.2161 7.76647C49.8881 7.39756 49.4537 7.21311 48.9127 7.21311C48.3716 7.21311 47.937 7.39756 47.6092 7.76647C47.2814 8.13536 47.1173 8.62725 47.1173 9.24208Z"
          fill="#131313"
        />
        <path
          d="M43.7095 8.3321C43.7095 7.95502 43.5865 7.65579 43.3408 7.43445C43.103 7.21313 42.7791 7.10245 42.3692 7.10245C41.9921 7.10245 41.6805 7.20901 41.4345 7.42215C41.1886 7.62712 41.0453 7.91404 41.0042 8.28293L39.7992 8.06157C39.8974 7.45495 40.1845 6.96719 40.66 6.59826C41.1354 6.22937 41.705 6.04492 42.3692 6.04492C43.1724 6.04492 43.8161 6.26215 44.2996 6.69666C44.7834 7.12293 45.0252 7.71728 45.0252 8.47968V12.3408H43.7095V11.394C43.5292 11.7055 43.2628 11.9597 42.9102 12.1564C42.5577 12.3449 42.1767 12.4392 41.7664 12.4392C41.3649 12.4392 41.0042 12.3613 40.6844 12.2056C40.3729 12.0498 40.127 11.8326 39.9467 11.5539C39.7746 11.2751 39.6885 10.9595 39.6885 10.607C39.6885 10.0004 39.922 9.52898 40.3895 9.19288C40.8648 8.85677 41.5822 8.63955 42.5413 8.54115L43.7095 8.41819V8.3321ZM41.0042 10.5701C41.0042 10.816 41.0985 11.0169 41.2872 11.1727C41.4756 11.3284 41.7257 11.4063 42.0369 11.4063C42.5538 11.4063 42.9595 11.2464 43.2544 10.9267C43.5495 10.607 43.7013 10.1889 43.7095 9.67244V9.41422L42.529 9.5495C42.0289 9.60688 41.6477 9.72574 41.3852 9.90609C41.1313 10.0782 41.0042 10.2996 41.0042 10.5701Z"
          fill="#131313"
        />
        <path
          d="M33.9639 6.14258H35.3289L36.8658 10.7784L38.4029 6.14258H39.7802L37.5177 12.3401H36.2266L33.9639 6.14258Z"
          fill="#131313"
        />
        <path
          d="M32.1893 6.14374H33.5298V12.3413H32.1893V6.14374ZM32.8535 5.11081C32.6076 5.11081 32.4025 5.02884 32.2386 4.86486C32.0747 4.69271 31.9927 4.48778 31.9927 4.25003C31.9927 4.0041 32.0747 3.79915 32.2386 3.6352C32.4025 3.46304 32.6076 3.37695 32.8535 3.37695C33.1075 3.37695 33.3166 3.46304 33.4807 3.6352C33.6526 3.79915 33.7387 4.0041 33.7387 4.25003C33.7387 4.48778 33.6526 4.69271 33.4807 4.86486C33.3166 5.02884 33.1075 5.11081 32.8535 5.11081Z"
          fill="#131313"
        />
        <path
          d="M31.6549 7.44675C31.4337 7.34838 31.1671 7.29917 30.8558 7.29917C30.3637 7.29917 29.9539 7.52872 29.6259 7.98781C29.3062 8.43867 29.1462 9.1191 29.1462 10.0291V12.3409H27.8062V6.14328H29.1462V7.32377C29.3103 6.95488 29.5727 6.65156 29.9336 6.41381C30.2942 6.16788 30.6629 6.04492 31.04 6.04492C31.3189 6.04492 31.5483 6.0818 31.7288 6.1556L31.6549 7.44675Z"
          fill="#131313"
        />
        <path
          d="M21.0342 3.73242H24.428C24.9936 3.73242 25.4898 3.83899 25.9159 4.05214C26.3423 4.25709 26.6701 4.55629 26.8999 4.9498C27.1292 5.3351 27.2439 5.78598 27.2439 6.30245C27.2439 6.8189 27.1292 7.27389 26.8999 7.66738C26.6701 8.05266 26.3423 8.3519 25.9159 8.56505C25.4898 8.76997 24.9936 8.87244 24.428 8.87244H22.4853V12.3401H21.0342V3.73242ZM24.3298 7.61819C24.7804 7.61819 25.133 7.50342 25.3873 7.27389C25.6494 7.03614 25.7806 6.71233 25.7806 6.30245C25.7806 5.89255 25.6494 5.57283 25.3873 5.34329C25.133 5.10556 24.7804 4.98667 24.3298 4.98667H22.4853V7.61819H24.3298Z"
          fill="#131313"
        />
      </g>
      <defs>
        <clipPath id={`url(#${gradientId})`}>
          <rect width="71.2" height="16" fill="white" transform="translate(0.799805)" />
        </clipPath>
      </defs>
    </svg>
  );
};
