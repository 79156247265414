import React, { SVGProps } from 'react';

export const Minus = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        d="M14.0976 8.89998C14.5947 8.89998 14.9976 8.49703 14.9976 7.99998C14.9976 7.50292 14.5947 7.09998 14.0976 7.09998L1.90232 7.09998C1.40526 7.09998 1.00232 7.50292 1.00232 7.99998C1.00232 8.49703 1.40526 8.89998 1.90232 8.89998L14.0976 8.89998Z"
        fill="currentcolor"
      />
    </svg>
  );
};
